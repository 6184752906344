  /** @format */

import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "../../asset/css/sidebar.css";
import activeAds from "../../asset/img/icon/Ads/activeAds.png";
import createAds from "../../asset/img/icon/Ads/createAds.png";
import expiredAds from "../../asset/img/icon/Ads/expiredAds.png";
import message from "../../asset/img/icon/Message.png";
import currentOrder from "../../asset/img/icon/Orders/currentOrder.png";
import pastOrder from "../../asset/img/icon/Orders/pastOrder.png";
import rejectOrder from "../../asset/img/icon/Orders/rejectOrder.png";
import addProfile from "../../asset/img/icon/Profile/addProfile.png";
import viewProfile from "../../asset/img/icon/Profile/viewProfile.png";
import account from "../../asset/img/icon/account.png";
import ads from "../../asset/img/icon/ads.png";
import availability from "../../asset/img/icon/availability.png";
import dashboard from "../../asset/img/icon/dashboard.png";
import orders from "../../asset/img/icon/orders.png";
import plan from "../../asset/img/icon/plan.png";
import profile from "../../asset/img/icon/profile.png";
import profile_logo from "../../asset/img/firstAdvertiserProfile.png";
import logo from "../../asset/img/logo.png";
import portugal from "../../asset/img/portugal.png";
import profileLogo from "../../asset/img/user.png";
import spain from "../../asset/img/spain.png";
import us from "../../asset/img/us.png";
import { useSelector } from "react-redux";
const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(true);
  const data = useSelector((state) => state.user_data);
  const [myOrderslocation, setmyOrderslocation] = useState();
  const [Adslocation, setAdslocation] = useState(false);
  const [profileLocatin, setProfilelocation] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isAccount,setAccount]=useState(false)
  const [myAccountLocation,setMyAccountLocation]=useState(false)
const newLocation = location.pathname
  const handleSearch = async () => {
    try {
      const response= await fetch(`https://socialmedia.quickvisit.me/api/get/globaly/search?search=${searchInput}`)
      if (!response.ok) {
        console.error(response);
      }
      const data = await response.json();
      console.log(data.advertisers)
      setSearchResult(data.advertisers)
    } catch (error) {
      console.error(error);
      // console.log(error);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchInput]);
  console.log(searchResult);

  function handleSidebarToggle(event) {
    event.preventDefault();
    if (window.innerWidth < 767) {
      document.body.classList.toggle("sb-sidenav-toggled");
      localStorage.setItem(
        "sb|sidebar-toggle",
        document.body.classList.contains("sb-sidenav-toggled")
      );
    }
  }
  function handleSidebarButton(event) {
    event.preventDefault();
    document.body.classList.toggle("sb-sidenav-toggled");
    localStorage.setItem(
      "sb|sidebar-toggle",
      document.body.classList.contains("sb-sidenav-toggled")
    );
  }

  useEffect(() => {
    const sidebarToggle = document.body.querySelector("#sidebarToggle");

    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", handleSidebarButton);

      return () => {
        sidebarToggle.removeEventListener("click", handleSidebarButton);
      };
    }
  }, []);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem("userToken");
      if (token) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
        navigate("/login");
      }
    };
    checkToken();
    const handleStorageChange = (event) => {
      if (event.key === "userToken") {
        checkToken();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [navigate]);

  useEffect(() => {
    const currentLocation = location.pathname;
    // console.log(currentLocation);

    const myAccount=[
      "/advertiser/myAccount",
      "/advertiser/updatePassword"
    ]

    const isMyAccountRoutes=myAccount.some(route=>currentLocation.startsWith(route))

    setMyAccountLocation(isMyAccountRoutes)

    if (
      currentLocation === "/advertiser/createAds" ||
      currentLocation === "/advertiser/activeAds" ||
      currentLocation==="/advertiser/editAds"||
      currentLocation === "/advertiser/expiredAds" ||
      currentLocation === "/advertiser/addProfile" ||
      currentLocation === "/advertiser/viewProfile"||
      currentLocation === "/advertiser/currentOrders"||
      currentLocation === "/advertiser/pastOrders"||
      currentLocation === "/advertiser/rejectedOrders"

    ) {
      setAdslocation(true);
      setProfilelocation(true);
      setmyOrderslocation(true)
    } else {
      setAdslocation(false);
      setProfilelocation(false);
      setmyOrderslocation(false)
    }
  }, [location]);

  useEffect(() => {
    const currentLocation = location.pathname;

    const isAdsLocation = [
      "/advertiser/createAds",
      "/advertiser/activeAds",
      "/advertiser/expiredAds",
      "/advertiser/editAds"
    ].includes(currentLocation);

    const isProfileLocation = [
      "/advertiser/addProfile",
      "/advertiser/viewProfile",
    ].includes(currentLocation);
    const isOrderLocation = [
      "/advertiser/currentOrders",
      "/advertiser/pastOrders",
      "/advertiser/rejectedOrders",

    ].includes(currentLocation);
 
     const isAccount =[
      '/advertiser/myAccount'
     ].includes(currentLocation)
     setAccount(isAccount)
     setmyOrderslocation(isOrderLocation)
    setAdslocation(isAdsLocation);
    setProfilelocation(isProfileLocation);
  }, [location]);
console.log(isAccount)
  const viewAdvertiser=(id)=>{
    console.log(id)
    navigate(`search-view-profile?id=${id}`);
  }
 
const handleClose =()=>{
  setAdslocation(false);
    setProfilelocation(false);
}

  return (
    <>
      <div id="wrapper" className="d-flex">
        <div id="sidebar-wrapper">
          <div className="d-flex flex-column flex-shrink-0 text-white sidebar">
            <div className="sidebar-heading">
              <div className="d-flex justify-content-center align-items-center">
                <span
                  className="dashboard-logo pointer"
                  onClick={() => navigate("/advertiser")}
                >
                  <img src={logo} alt="logo" className="img-fluid w-50" />
                </span>

                <button
                  className="btn togglebtn btn-toggle-sidebar position-absolute end-0"
                  onClick={handleSidebarButton}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div
              className="list-group list-group-flush accordion"
              id="accordion"
            >
              <ul className="nav nav-pills flex-column mb-auto sidebar_advertiser">
                <li>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      exact
                      activeclassname="active"
                      to="/advertiser"
                      className="nav-link text-white d-flex align-items-center sidebarmenu"
                      end
                    >
                      <img src={dashboard} alt="dashboard" />
                      <span className="mx-3 iconmenu">Dashboard</span>
                    </NavLink>
                  </div>
                </li>
{/* profile */}
                <li>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <Link
                      activeclassname="active"
                      // to="/advertiser/addProfile"
                      onClick={()=>navigate('/advertiser/addProfile')}
                      className={
                        profileLocatin || newLocation ==='/advertiser/search-view-profile'
                          ? "nav-link text-white position-relative d-flex align-items-center sidebarmenu active"
                          : "nav-link text-white position-relative d-flex align-items-center sidebarmenu"
                      }
                      exact="true"
                      data-bs-toggle="collapse"
                      data-bs-target="#submenu-1"
                      aria-expanded={profileLocatin ? true :false}

                    >
                      <img src={profile} alt="profile" />
                      <span className="mx-3 iconmenu menudropdown">
                        <span>Profile </span>
                        <i className="fa fa-caret-fa float-end position-absolute"></i>
                      </span>
                    </Link>
                    <div
                      id="submenu-1"
                      className={`accordion-collapse collapse ${profileLocatin ? 'show' :'hide'}`}
                      data-bs-parent="#accordion"
                    >
                      <ul className="navi nav-list">
                        <li className="dropdown-item" style={{paddingTop:"0.5rem"}}>
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/advertiser/addProfile"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={addProfile} alt="profile" />
                              <span className="iconmenu sub-menu-text">
                                &nbsp; Add Profile
                              </span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/advertiser/viewProfile"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={viewProfile} alt="profile" />
                              <span className="iconmenu sub-menu-text">
                                &nbsp; View Profile
                              </span>
                            </NavLink>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>

                {/* ads */}
                <li>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <Link
                      
                      // to="/advertiser/activeAds"
                      onClick={() => navigate("/advertiser/createAds")}

                      className={
                        Adslocation 
                          ? "nav-link text-white position-relative d-flex align-items-center sidebarmenu active":
                          isAccount ? "nav-link text-white position-relative d-flex align-items-center sidebarmenu collapsed":
                          "nav-link text-white position-relative d-flex align-items-center sidebarmenu"
                      }
                      activeclassname="active"
                      exact="true"
                      data-bs-toggle="collapse"
                      data-bs-target="#submenu2"
                      aria-expanded={Adslocation ? true :false}
                    >
                      <img src={ads} alt="ads" />
                      <span className="mx-3 iconmenu menudropdown">
                        <span>Ads</span>
                        <i className="fa fa-caret-fa float-end position-absolute"></i>
                      </span>
                    </Link>
                    <div
                      id="submenu2"
                      className={`accordion-collapse collapse ${Adslocation ? 'show' :'hide'}`}
                      data-bs-parent="#accordion"
                    >
                      <ul className="navi nav-list">
                        <li className="dropdown-item" style={{paddingTop:"0.5rem"}}>
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/advertiser/createAds"
                              onClick={() =>
                                setAdslocation("/advertiser/createAds")
                              }
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={createAds} alt="profile" />
                              <span className="iconmenu sub-menu-text">
                                {" "}
                                &nbsp; Create Ads
                              </span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item ">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/advertiser/activeAds"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={activeAds} alt="profile" />
                              <span className="iconmenu sub-menu-text">
                                {" "}
                                &nbsp; Active Ads
                              </span>
                            </NavLink>{" "}
                          </div>
                        </li>
                        <li className="dropdown-item ">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/advertiser/expiredAds"
                              className="nav-link text-white d-flex align-items-center p-0  sidebarmenu sidebarmenuitem"
                            >
                              <img src={expiredAds} alt="profile" />
                              <span className="iconmenu sub-menu-text">
                                {" "}
                                &nbsp; Expired Ads
                              </span>
                            </NavLink>{" "}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                {/* paln */}
                <li onClick={handleClose}>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/advertiser/plan"
                      className="nav-link text-white d-flex align-items-center sidebarmenu"
                    >
                      <img src={plan} alt="profile" />
                      <span className="mx-3 iconmenu">Plan</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/advertiser/myMessage"
                      className="nav-link text-white  d-flex align-items-center sidebarmenu"
                    >
                      <img src={message} alt="message" />
                      <span className="mx-3 iconmenu">My Message</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/advertiser/myAccount"
                      className={myAccountLocation ?"nav-link text-white  d-flex align-items-center sidebarmenu active":"nav-link text-white  d-flex align-items-center sidebarmenu"}
                    >
                      <img src={account} alt="profile" />
                      <span className="mx-3 iconmenu">My Account</span>
                    </NavLink>
                  </div>
                </li>
{/* my orders */}
                <li>
                  
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <Link
                      // to={
                      //   myOrderslocation
                      //     ? myOrderslocation
                      //     : "/advertiser/currentOrders"
                      // }
                      onClick={()=>navigate('/advertiser/currentOrders')}
                      className={
                        myOrderslocation
                          ? "nav-link text-white position-relative d-flex align-items-center sidebarmenu active"
                          : "nav-link text-white position-relative d-flex align-items-center sidebarmenu"
                      }
                      exact="true"
                      activeclassname="active"
                      data-bs-toggle="collapse"
                      data-bs-target="#submenu3"
                      aria-expanded={myOrderslocation ? true :false}
                  
                    >
                      <img src={orders} alt="profile" />
                      <span className="mx-3 iconmenu menudropdown">
                        <span>My Orders </span>
                        <i className="fa fa-caret-fa float-end position-absolute"></i>
                      </span>
                    </Link>
                    <div
                      id="submenu3"
                      className={`accordion-collapse collapse ${myOrderslocation ? 'show' :'hide'}`}
                      data-bs-parent="#accordion"
                    >
                      <ul className="navi nav-list">
                        <li className="dropdown-item" style={{paddingTop:"0.5rem"}}>
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/advertiser/currentOrders"
                              onClick={() =>
                                setmyOrderslocation("/advertiser/currentOrders")
                              }
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={currentOrder} alt="profile" />
                              <span className="iconmenu sub-menu-text">
                                {" "}
                                &nbsp;Current Order
                              </span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item ">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/advertiser/pastOrders"
                              onClick={() =>
                                setmyOrderslocation("/advertiser/pastOrders")
                              }
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={pastOrder} alt="profile" />
                              <span className="iconmenu sub-menu-text">
                                &nbsp; Past Order
                              </span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item ">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/advertiser/rejectedOrders"
                              onClick={() =>
                                setmyOrderslocation(
                                  "/advertiser/rejectedOrders"
                                )
                              }
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={rejectOrder} alt="profile" />
                              <span className="iconmenu sub-menu-text">
                                {" "}
                                &nbsp; Rejected Order
                              </span>
                            </NavLink>{" "}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* NavBar */}

        <div className="page-content-wrapper ">
          <nav className="navbar navbar-expand-lg dash-header" id="header">
            <div className="container-fluid">
              <div className="text-light togglebar" id="sidebarToggle">
                {/* <i className="fa fa-bars"></i> */}
                <i className="fa fa-bars-staggered"></i>
              </div>
              <span
                className="dashboard-logo-menu"
                onClick={() => navigate("/advertiser")}
              >
                <img src={logo} alt="logo" className="img-fluid" />
              </span>

              <span className="position-relative input-wrapper dropdown">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-ctm searchip dropdown-toggle"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
                <i className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass"></i>

                {searchResult ? (
                  <ul
                    className="dropdown-menu"
                    style={{ backgroundColor: "black", width: "100%" }}
                  >
                    {searchResult.length > 0 ? (
                      searchResult.map((user, i) => (
                        <li key={i}>
                          <a
                            className="dropdown-item search-dropdown"
                            onClick={()=>viewAdvertiser(user.ad_id)}
                            // href="/advertiser/search-view-profile"
                            style={{ color: "red" }}
                          >
                            {user.username}
                          </a>
                        </li>
                      ))
                    ) : (
                      <li>
                        <a
                          className="dropdown-item search-dropdown-1"
                          href="#"
                          style={{ color: "red" }}
                        >
                          No Result
                        </a>
                      </li>
                    )}
                  </ul>
                ) : null}
              </span>
              <span className="nav-flag">
                <span className="navflag mx-1 navflagdash">
                  <img src={us} alt="us flag" />
                </span>
                <span className="navflag mx-1 navflagdash">
                  <img src={portugal} alt="prt flag" />
                </span>
                <span className="navflag mx-1 navflagdash">
                  <img src={spain} alt="Spain flag" />
                </span>
              </span>
              <span className="navbar-nav position-relative me-3">
                <span
                  className="pro"
                  exact="true"
                  activeclassname="active"
                  data-toggle="collapse"
                  data-target="#submenu4"
                >
                  <div
                    className="dropdown-icon d-flex align-items-center "
                    type="button"
                    data-bs-toggle="dropdown"
                    data-auto-close="outside"
                    aria-expanded="false"
                  >
                    <span className="profile_user">
                      <img
                        src={
                          data.profile_photo ? data.profile_photo : profileLogo
                        }
                        alt="profile"
                      />
                    </span>
                  </div>
                  <ul
                    className="dropdown-menu collapse profile_menu"
                    id="submenu4"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/advertiser/myAccount"
                      >
                        My Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/"
                        onClick={() => localStorage.clear()}

                      >
                        Log Out
                      </Link>
                    </li>
                  </ul>
                </span>
              </span>
            </div>
          </nav>

          {children}

          <Outlet />

          <div className=" p-3 fixed-bottom1">
            <div className="container-fluid">
              <div className="d-flex align-items-center justify-content-between small text-center">
                <div className="text-muted">
                  <span className="copyright quick-links footer_height text-light">
                    Copyright © 2024{" "}
                    <Link to="/" className="text-light">
                      QuickVisit.com
                    </Link>{" "}
                    All Rights Reserved{" "}
                    <Link to="/terms-of-use" className="text-light">
                      Term and Conditions
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
