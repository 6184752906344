import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { availableDays, isLoader, isToaster, userData } from "../../actions";
import advertiserPic from "../../../src/asset/img/advertiserPic.png";
import camera from "../../../src/asset/img/camera.png";
import DisplayAvailability from "../component/DisplayAvailability";
import play from "../../../src/asset/img/play.png";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import dummyimg from '../../../src/asset/img/userpro.png'
import $ from "jquery";
import axios from "axios";
 
const SeeProfile = () => {
  let token = localStorage.getItem("userToken");
  const data = useSelector((state) => state.user_data);
  const dispatch = useDispatch();
  const location = useLocation();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState();
  const [availableDay, setAvailableDay] = useState([]);
  const [allAdvertiser, setAllAdvertiser] = useState([]);
  let [edata, setEdata] = useState(data);
  const [advertiser, setAdvertiser] = useState(null);
  const [advertiserId, setAdvertiserId] = useState(null);
 
  const onloadmore = () => {
    dispatch(isLoader(true));
    setTimeout(() => {
      dispatch(isLoader(false));
    }, 1000);
  };
 
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id");
 
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://socialmedia.quickvisit.me/api/get/advertisment/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
          }
        );
 
        // if (!response.ok) {
        //   throw new Error(`HTTP error! status: ${response.status}`);
        // }
 
        const data = await response.json();
        setAdvertiser(data?.availableAdvertisements);
        console.log(data,"jjhnhv");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
 
    fetchData();
  }, [location.search]);
 
 
 
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <h4>View Profile</h4>
              </div>
            </div>
          </div>
 
          <div className="row position-relative">
            <div className="col-lg-12">
              <div
                className="headerimage-bg"
                style={{ backgroundImage: `url(${advertiser ?advertiser?.cover_photo :null})` }}
                id="headerimage-bg"
              >
                <div className="row justify-content-center">
                  <div className="col-12 col-md-6 text-center one-fourth-screen d-flex flex-column justify-content-center sm-h-350px">
                    <h1 className="headerimage-heading text-white font-weight-600 text-uppercase d-block m-0">
                      {advertiser ? advertiser?.fullname : "name"}
                    </h1>
                    <span className="headerimage-text text-uppercase text-small text-white d-inline-block ">
                      Escort model from{" "}
                      {advertiser ? advertiser.location : "city"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-section-inner  rounded">
              <img
                src={advertiser?.profile_photo ? advertiser?.profile_photo : dummyimg}
                alt="Emily"
                id="cover_photo"
                className="img-fluid model-round-img"
              />
            </div>
          </div>
 
          <div className="row viewcardtop">
            <div className="col-lg-12">
              <div className="viewcard ">
                <div className="row position-relative basic-detail">
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="infoedit m-0"> Basic Info </h4>
                  </div>
                  <span className="basic-detail-border"></span>
                </div>
                <div className="row mt-3">
                  <div className="readonly-form">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Name</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.fullname : null}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Hair Color</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.hair_color : null}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Gender</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.gender : null}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Body Type</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.body_type : null}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Age</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.age : null}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Ethnicity</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.ethnicity : null}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Eye Color</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.eye_color : null}
                        </h6>
                      </div>
                      <div
                        className={`col-lg-6 col-md-6 col-sm-12 ${
                          data.gender === "Male" ? "d-none" : null
                        }`}
                      >
                        <p className="basicinfo">Breast Size</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.breast_size : null}
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo">Height</p>
                        <h6 className="basicdata">
                          {advertiser ? advertiser.height : null}
                        </h6>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <p className="basicinfo"> </p>
                        <h6 className="basicdata"> </h6>
                      </div>
                    </div>
                    <DisplayAvailability />
                  </div>
                </div>
              </div>
            </div>
          </div>
 
          <div className="row viewcardtop1 viewpro-img">
            {data.images
              ? data.images.map((x) => {
                  return (
                    <div className="col-lg-3 col-md-3 col-xs-6">
                      <div className="view-profile-img">
                        <img
                          src={x.image}
                          style={{ objectFit: "cover" }}
                          alt="advertiser"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
 
          <div className="row mt-4 viewcardtop1">
            {data.videos
              ? data.videos.map((x) => {
                  return (
                    <div className="col-lg-6 col-md-6">
                      <div className="video-container">
                        <video
                          id="videoElement"
                          className="w-100"
                          loop
                          muted
                          controls
                        >
                          <source
                            src={x.video}
                            loop
                            className="hover-to-play w-100"
                          />
                        </video>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </section>
    </>
  );
};
 
export default SeeProfile;