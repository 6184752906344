
/** @format */
 
import React, { useEffect,useRef } from "react";
import userLogo from "../../../asset/img/user.png";
import camera from "../../../asset/img/camera.png";
import video from "../../../asset/img/video.png";
import { useSelector } from "react-redux";
import { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { availableDays, isLoader, isToaster, userData } from "../../../actions";
import { useNavigate } from "react-router-dom";
import UpdateAvailability from "../../component/UpdateAvailability";
 
const AddProfile = () => {
  let token = localStorage.getItem("userToken");
  const availableWeekDays = useSelector((s) => s.available_days);
  console.log(availableWeekDays);
  const data = JSON.parse(localStorage.getItem("userData"));
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [profileImageFile, setprofileImageFile] = useState("");
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [videosToUpload, setVideosToUpload] = useState([]);
  let navigate = useNavigate();
  const [user, setUser] = useState({
    userProfileImage: data.profile_photo ? data.profile_photo : "",
    firstname: data.firstname ? data.firstname : "",
    gender: data.gender,
    age: data.age,
    ethnicity: data.ethnicity ? data.ethnicity : "",
    height: data.height ? data.height : "",
    bSize: data.gender === "Male" ? "" : "",
    eyeColor: data.eye_color ? data.eye_color : "",
    hairColor: data.hair_color ? data.hair_color : "",
    bodyType: data.body_type ? data.body_type : "",
    userImages: data.images ? data.images.map((x) => x) : [],
    userVideos: data.videos ? data.videos.map((x) => x) : [],
    availability: data.availability,
  });
  const [updateImg, setUpdateImg] = useState(false);
  const [updateVideo, setUpdateVideo] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const imgInputRef=useRef(null)
  const videoInputRef=useRef(null)
  const [maxVideoToUpload, setMaxVideoToUpload] = useState(0);
  console.log(data);
 
  const handleImageChange = (event) => {
    const maxFileSizeInBytes = 2 * 1024 * 1024;
    const allowedExtensions = ["jpeg", "jpg", "png"];
 
    const file = event.target.files[0];
    console.log(file)
    if (!file) {
      return; 
    }
 
    // Check file size
    if (file.size > maxFileSizeInBytes) {
      dispatch(
        isToaster({
          flag: true,
          msg: "The selected file exceeds the maximum file size of 2 MB!",
        })
      );
      return; 
    }
 
    // Check file type
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      dispatch(
        isToaster({
          flag: true,
          msg: "Only JPEG, JPG, and PNG files are allowed!",
        })
      );
      return; 
    }
 
    // Check image upload limit based on user plan
    const maxUploads =
      data.plan === "Premium" || data.plan === "Standard"
        ? 4
        : data.plan === "Basic"
        ? 3
        : data.plan === "Free"
        ? 1
        : 0;
 
    if (user.userImages.length >= maxUploads) {
      dispatch(
        isToaster({
          flag: true,
          msg: `You reached the maximum limit of image uploads!`,
        })
      );
      return; // Don't proceed further
    }
 
    // If all validations pass, proceed with uploading
    const imageKey = `image`;
    const timestamp = new Date().getTime();
    setUser({
      ...user,
      userImages: [
        ...user.userImages,
        { [imageKey]: URL.createObjectURL(file), time: timestamp },
      ],
    });
    setImagesToUpload([...imagesToUpload, { [imageKey]: file, id: timestamp }]);
 
    console.log("images in user state", user.userImages);
    console.log("images to be uploaded", imagesToUpload);
  };
 
  const handleVideo = (event) => {
    const maxFileSizeInBytes = 10 * 1024 * 1024; // Updated to 10 MB
    const allowedExtension = "mp4";
 
    if (event.target.files.length === 0) {
      return; // No file selected
    }
 
    const file = event.target.files[0];
 
    // Check file size
    if (file.size > maxFileSizeInBytes) {
      dispatch(
        isToaster({
          flag: true,
          msg: "The selected file exceeds the maximum file size of 10 MB!",
        })
      );
      return; // Don't proceed further
    }
 
    // Check file type
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (fileExtension !== allowedExtension) {
      dispatch(
        isToaster({
          flag: true,
          msg: `Only .${allowedExtension} files are allowed!`,
        })
      );
      return; // Don't proceed further
    }
 
    console.log(data.plan);
    // Check video upload limit based on user plan
    const maxUploads =
      data.plan === "Premium"
        ? 4
        : data.plan === "Standard"
        ? 3
        : data.plan === "Basic"
        ? 2
        : data.plan === "Free"
        ? 1
        : 0;
 
    if (user.userVideos.length >= maxUploads) {
      dispatch(
        isToaster({
          flag: true,
          msg: `You reached the maximum limit of video uploads!`,
        })
      );
      return;
    }
 
    // If all validations pass, proceed with uploading
    const videoKey = `video`;
    const timestamp = new Date().getTime();
    setUser({
      ...user,
      userVideos: [...user.userVideos, { [videoKey]: file, id: timestamp }],
    });
    setVideosToUpload([...videosToUpload, { [videoKey]: file, id: timestamp }]);
 
    console.log("videos in user state", user.userVideos);
    console.log("videos to be uploaded", videosToUpload);
  };
 
  const profileUpload = (e) => {
    const maxFileSizeInBytes = 2 * 1024 * 1024;
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const file = e.target.files[0];
 
    if (!file) {
      return; // No file selected
    }
 
    // Check file size
    if (file.size > maxFileSizeInBytes) {
      dispatch(
        isToaster({
          flag: true,
          msg: "The selected file exceeds the maximum file size of 2 MB!",
        })
      );
      return; // Don't proceed further
    }
 
    // Check file type
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      dispatch(
        isToaster({
          flag: true,
          msg: "Only JPEG, JPG, and PNG files are allowed!",
        })
      );
      return; // Don't proceed further
    }
 
    // If both size and type are valid, proceed with uploading
    setprofileImageFile(file);
    const image_url = URL.createObjectURL(file);
    setUser({ ...user, userProfileImage: image_url });
  };

 
  const onSave = () => {
    const getAdvertiser = async () => {
      if (!token) {
        navigate("/login");
        return;
      }
 
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/get-advertiser`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        dispatch(userData(res.data.user));
        navigate("/advertiser/viewProfile");
      } catch (err) {
        dispatch(
          isToaster({
            flag: true,
            msg: err.response?.data?.message || "Error fetching advertiser",
          })
        );
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };
 
    const updateVideos = async () => {
      const maxUploads =
        data.plan === "Premium"
          ? 4
          : data.plan === "Standard"
          ? 3
          : data.plan === "Basic"
          ? 2
          : data.plan === "Free"
          ? 1
          : 0;
 
      const postVideo = {
        videos: videosToUpload.slice(0, maxUploads).map((x) => x.video),
        oldVideoIds: user.userVideos.map((x) => x.id).join(","),
      };
 
      setLoading(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/update-videos`,
          postVideo,
          customConfig
        );
        dispatch(
          isToaster({ flag: true, msg: "Videos updated Successfully!" })
        );
      } catch (err) {
        dispatch(
          isToaster({
            flag: true,
            msg: err.response?.data?.message || "Error updating videos",
          })
        );
      } finally {
        setLoading(false);
      }
    };
 
    const customConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
 
    const postData = {
      ethnicity: user.ethnicity,
      firstname: user.firstname,
      height: user.height,
      breastSize: user.bSize,
      eyeColor: user.eyeColor,
      hairColor: user.hairColor,
      bodyType: user.bodyType,
      profilePhoto: profileImageFile,
      availability: availableWeekDays,
    };
 
    const maxUploads =
      data.plan === "Premium" || data.plan === "Standard"
        ? 4
        : data.plan === "Basic"
        ? 3
        : data.plan === "Free"
        ? 1
        : 0;
 
    const postImage = {
      images: imagesToUpload.slice(0, maxUploads).map((x) => x.image),
      oldImageIds: user.userImages.map((x) => x.id).join(","),
    };
 
    setLoading(true);
    Promise.all([
      axios.post(
        `${process.env.REACT_APP_API_URL}/api/update-advertiser`,
        postData,
        customConfig
      ),
      axios.post(
        `${process.env.REACT_APP_API_URL}/api/update-images`,
        postImage,
        customConfig
      ),
    ])
      .then(async ([updateAdvertiserRes, updateImagesRes]) => {
        dispatch(
          isToaster({
            flag: true,
            msg: "Profile and images updated Successfully!",
          })
        );
        await updateVideos(); // Call to update videos can still be done here
        await getAdvertiser(); // Fetch advertiser data after updates
      })
      .catch((err) => {
        dispatch(
          isToaster({
            flag: true,
            msg:
              err.response?.data?.message || "Error updating profile or images",
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
    const handleReset = () => {

    dispatch(isLoader(true));
    console.log(token)
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get-advertiser`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.user.availability != null) {
          dispatch(availableDays(res.data.user.availability));
        } else {
          dispatch(availableDays([]));
        }
 
        dispatch(isLoader(false));
        dispatch(userData(res.data.user));
        setUser({
          ...user,
          userProfileImage: res.data.user.profile_photo,
          ethnicity: res.data.user.ethnicity,
          height: res.data.user.height,
          bSize: res.data.user.breast_size,
          eyeColor: res.data.user.eye_color,
          hairColor: res.data.user.hair_color,
          bodyType: res.data.user.body_type,
          userImages: res.data.user.images,
          userVideos: res.data.user.videos,
          availability:
            res.data.user.availability != null
              ? res.data.user.availability
              : [],
        });
      })
      .catch((err) => {
        navigate("/login");
      });
      if(imagesToUpload||videosToUpload){
        setImagesToUpload([])
        setVideosToUpload([])
      }
  };
 
  const heightArray = [];
  for (let i = 152; i <= 210; i++) {
    heightArray.push(i);
  }
 
  const bSizeArray = [];
  for (let i = 34; i <= 38; i += 2) {
    bSizeArray.push(i + " (A)");
    bSizeArray.push(i + " (B)");
    bSizeArray.push(i + " (C)");
  }
 
  const ageArray = [];
  for (let i = 18; i <= 60; i++) {
    ageArray.push(i);
  }
 
  useEffect(() => {
    dispatch(isLoader(true));
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/get-advertiser`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.user.availability != null) {
          dispatch(availableDays(res.data.user.availability));
        } else {
          dispatch(availableDays([]));
        }
 
        dispatch(isLoader(false));
        dispatch(userData(res.data.user));
        setUser({
          ...user,
          userProfileImage: res.data.user.profile_photo,
          ethnicity: res.data.user.ethnicity,
          height: res.data.user.height,
          bSize: res.data.user.breast_size,
          eyeColor: res.data.user.eye_color,
          hairColor: res.data.user.hair_color,
          bodyType: res.data.user.body_type,
          userImages: res.data.user.images,
          userVideos: res.data.user.videos,
          availability:
            res.data.user.availability != null
              ? res.data.user.availability
              : [],
        });
      })
      .catch((err) => {
        navigate("/login");
      });
  }, []);
 
  let name, Value;
  const handleInputs = (e) => {
    name = e.target.name;
    Value = e.target.value;
    setUser({ ...user, [name]: Value });
  };
  const deleteImageEntry = (Id) => {
    console.log("Id to delete: ", Id);
    let arr = user.userImages.filter((x) => {
      if (x.id) {
        return x.id !== Id;
      } else if (x.time) {
        return x.time !== Id;
      }
    });
    let arr2 = imagesToUpload.filter((x) => {
      return x.id !== Id;
    });
    setUser({
      ...user,
      userImages: [...arr],
    });
    setImagesToUpload(arr2);
    console.log("user images data after delete button: ", user.userImages);
  };
 
  const deleteVideoEntry = (Id) => {
    let arr = user.userVideos.filter((x) => {
      if (x.id) {
        return x.id !== Id;
      } else if (x.time) {
        return x.time !== Id;
      }
    });
    let arr2 = videosToUpload.filter((x) => {
      return x.id !== Id;
    });
    // let arr = user.userVideos.filter((x) => x.id !== Id)
    setUser({ ...user, userVideos: [...arr] });
    setVideosToUpload(arr2);
  };

  useEffect(() => {
    if (data) {
      console.log(data.plan);
      const maxUploads =
        data.plan === "Premium"
          ? 4
          : data.plan === "Standard"
          ? 3
          : data.plan === "Basic"
          ? 2
          : data.plan === "Free"
          ? 1
          : 0;

      setMaxVideoToUpload(maxUploads);
    }
  }, [data]);
  return (
    <>
      <section className="addash">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <h4>Add Profile</h4>
              </div>
            </div>
          </div>
 
          <div className="row">
            <div className="col-lg-12">
              <div className="addprofile">
                <form autoComplete="off" noValidate="noValidate">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="d-flex justify-content-center">
                        <div className="pic-holder">
                          <img
                            src={
                              user.userProfileImage
                                ? user.userProfileImage
                                : userLogo
                            }
                            alt="UploadPhoto"
                            id="blah1"
                            className="pic"
                          />
                          <label
                            htmlFor="newProfilePhoto"
                            className="upload-file-block"
                          >
                            <input
                              id="newProfilePhoto"
                              className="form-control mb-2"
                              type="file"
                              onChange={profileUpload}
                              accept="image/*"
                            />
                            <span className="text-center">
                              <i className="fa fa-camera fa-2x"></i>
                              <br />
                              Update <br /> Profile Photo
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row input-label">
                    <div className="col-lg-4 col-md-4">
                      <label className="mb-1">
                        <span>Name</span>
                      </label>
                      <input
                        onChange={handleInputs}
                        name="firstname"
                        value={user.firstname}
                        // disabled
                        type="text"
                        // placeholder="Enter Name"
                        className="form-control form-control-ctm mb-2"
                      />
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <label className="mb-1">
                        <span>Gender</span>
                      </label>
                      <select
                        className="form-select-ctm pointer_d mb-2"
                        aria-label="select"
                        name="gender"
                        disabled
                        value={data.gender}
                        onChange={handleInputs}
                      >
                        <option disabled selected>
                          -Gender-
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Non-Binary">Non-Binary</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <label className="mb-1">
                        <span>Age</span>
                      </label>
                      <input
                        type="number"
                        onChange={handleInputs}
                        name="age"
                        disabled
                        value={data.age}
                        // placeholder="Age"
                        className="form-control form-control-ctm pointer_d mb-2"
                      />
                    </div>
                  </div>
 
                  <div className="row input-label btn-gap-top">
                    <div className="col-lg-4 col-md-4">
                      <label className="mb-1">
                        <span>Ethnicity</span>
                      </label>
                      <select
                        aria-label="select"
                        onChange={handleInputs}
                        name="ethnicity"
                        value={user.ethnicity}
                        // placeholder="Ethnicity"
                        className="form-select-ctm mb-2"
                      >
                        <option selected>-Ethnicity-</option>
                        <option value="White American">White American</option>
                        <option value="European American">
                          European American
                        </option>
                        <option value="Middle Eastern American">
                          Middle Eastern American
                        </option>
                        <option value="Asian">Asian</option>
                        <option value="African">African</option>
                        <option value="Australian">Australian</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <label className="mb-1">
                        <span>Height</span>
                      </label>
                      <select
                        aria-label="select"
                        onChange={handleInputs}
                        name="height"
                        value={user.height}
                        // placeholder="Height"
                        className=" form-select-ctm mb-2"
                      >
                        <option selected>-Height-</option>
                        {heightArray.map((x) => {
                          return <option value={x}>{x} cm</option>;
                        })}
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <label className="mb-1">
                        <span>Body Type</span>
                      </label>
                      <select
                        aria-label="select"
                        onChange={handleInputs}
                        name="bodyType"
                        value={user.bodyType}
                        // placeholder="Body Type"
                        className=" form-select-ctm mb-2"
                      >
                        <option selected>-Body Type-</option>
                        {data.gender === "Male" ? (
                          <>
                            <option value="Rectangle">Rectangle</option>
                            <option value="Triangle">Triangle</option>
                            <option value="Oval">Oval</option>
                            <option value="Trapezoid">Trapezoid</option>
                            <option value="Mesomorph">Mesomorph</option>
                            <option value="Ectomorph">Ectomorph</option>
                            <option value="Endomorph">Endomorph</option>
                          </>
                        ) : (
                          <>
                            <option value="Slim">Slim</option>
                            <option value="Triangle">Triangle</option>
                            <option value="Hour glass">Hour glass</option>
                            <option value="Rectangle">Rectangle</option>
                            <option value="Apple">Apple</option>
                            <option value="Pear">Pear</option>
                          </>
                        )}
                      </select>
                    </div>
                  </div>
 
                  <div className="row input-label btn-gap-top">
                    <div className="col-lg-4 col-md-4">
                      <label className="mb-1">
                        <span>Eye Color</span>
                      </label>
                      <select
                        aria-label="select"
                        onChange={handleInputs}
                        name="eyeColor"
                        value={user.eyeColor}
                        // placeholder="Eye Color"
                        className=" form-select-ctm mb-2"
                      >
                        <option selected>-Eye Color-</option>
                        <option value="Black">Black</option>
                        <option value="Grey">Grey</option>
                        <option value="Golden">Golden</option>
                        <option value="Red">Green</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-4">
                      <label className="mb-1">
                        <span>Hair Color</span>
                      </label>
                      <select
                        aria-label="select"
                        onChange={handleInputs}
                        name="hairColor"
                        value={user.hairColor}
                        // placeholder="Hair Color"
                        className=" form-select-ctm mb-2"
                      >
                        <option selected>-Hair Color-</option>
                        <option value="Black">Black</option>
                        <option value="Grey">Grey</option>
                        <option value="Golden">Golden</option>
                        <option value="Red">Red</option>
                        <option value="Blonde">Blonde</option>
                        <option value="Multicolor">Multicolor</option>
                      </select>
                    </div>
                    <div
                      className={`col-lg-4 col-md-4 ${
                        data.gender === "Male" ? "d-none" : null
                      }`}
                    >
                      <label className="mb-1">
                        <span>Breast Size</span>
                      </label>
                      <select
                        aria-label="select"
                        onChange={handleInputs}
                        disabled={data.gender === "Male" ? true : false}
                        name="bSize"
                        value={user.bSize}
                        // placeholder="Breast Size"
                        className={`form-select-ctm mb-2 ${
                          data.gender === "Male" ? "pointer_d" : null
                        }`}
                      >
                        <option selected>-Breast Size-</option>
                        {bSizeArray.map((x) => {
                          return <option value={x}>{x}</option>;
                        })}
                      </select>
                    </div>
                  </div>
 
                  <div className="row input-label mt-3">
                    <div className="col-lg-12">
                      {user.availability ? (
                        <UpdateAvailability available={user.availability} />
                      ) : null}
                    </div>
                  </div>
 
                  <div>
                    <div className="row input-label mt-4">
                      <div className="col-lg-12">
                        <div className="text-center input-pic-border">
                          <h5>Add Photos</h5>
                          <p className="m-0">
                            Please add a few recent images to your application.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row input-label mt-3">
                      <div className="col-lg-12">
                        <div className="text-center input-pic-border-pic">
                          <div className="d-flex justify-content-center">
                            <label
                              htmlFor="uploadPhoto"
                              className="input-images"
                            >
                              <img
                                src={camera}
                                className="rounded-circle mb-4"
                                alt="multiple images"
                              />
                              <br />
                              <span className="upload-btn mt-4">
                                Select Your Images
                              </span>
                            </label>
                            <input
                              id="uploadPhoto"
                              type="file"
                              ref={imgInputRef}
                              onChange={handleImageChange}
                              multiple={true}
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
 
                  <div className="img_h_center_container mt-3">
                    {user.userImages.map((x) => {
                      return (
                        <>
                          <div className="img_v_center">
                            <img
                              src={x.image}
                              style={{ objectFit: "cover" }}
                              alt=""
                              width={"200px"}
                            />
                            <i
                              onClick={() =>
                                deleteImageEntry(x.time ? x.time : x.id)
                              }
                              className="fa-solid fa-xmark pointer"
                            ></i>
                          </div>
                        </>
                      );
                    })}
                  </div>
 
                  <div>
                    <div className="row input-label mt-4">
                      <div className="col-lg-12">
                        <div className="text-center input-pic-border">
                          <h5>Add Videos</h5>
                          <p className="m-0">
                        {`  You can upload a maximum of ${maxVideoToUpload} videos, each limited to 15 seconds.`}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row input-label mt-3">
                      <div className="col-lg-12">
                        <div className="text-center input-pic-border-pic">
                          <div className="d-flex justify-content-center">
                            <label
                              htmlFor="uploadVideo"
                              className="input-images"
                            >
                              <img
                                src={video}
                                className="rounded-circle mb-4"
                                alt="multiple images"
                              />
                              <br />
                              <span className="upload-btn mt-4">
                                Select Your Videos
                              </span>
                            </label>
                            <input
                              id="uploadVideo"
                              type="file"
                              onChange={handleVideo}
                              ref={videoInputRef}
                              multiple={true}
                              accept="video/*"
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="img_h_center_container">
                    {user.userVideos.map((x) => {
                      return (
                        <div className="img_v_center">
                          <video
                            src={x.video}
                            alt=""
                            loop
                            muted
                            width={"200px"}
                            controls
                          />
                          <i
                            onClick={() => deleteVideoEntry(x.id)}
                            className="fa-solid fa-xmark pointer"
                          ></i>
                        </div>
                      );
                    })}
                  </div>
                </form>
              </div>
            </div>
          </div>
 
          <div className="row mt-4">
            <div className="col-lg-9 col-md-8"></div>
            <div className="col-lg-3 col-md-4 col-sm-12 sutbtn">
              <span>
                <button
                  type="button"
                  disabled={loading}
                  className="btn cus-btn"
                  onClick={onSave}
                >
                  Save&nbsp;
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : null}
                </button>
              </span>
              <span className="m-btn">
                <button
                  type="button"
                  className="btn sec-btn"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
 
export default AddProfile;