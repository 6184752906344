/** @format */

import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "../../asset/css/sidebar.css";
import activeAds from "../../asset/img/icon/Ads/activeAds.png";
import createAds from "../../asset/img/icon/Ads/createAds.png";
import expiredAds from "../../asset/img/icon/Ads/expiredAds.png";
import message from "../../asset/img/icon/Message.png";
import account from "../../asset/img/icon/account.png";
// import notifica from "../../../public/bell.svg";

import ads from "../../asset/img/icon/ads.png";
import availability from "../../asset/img/icon/availability.png";
import dashboard from "../../asset/img/icon/dashboard.png";
// import plan from "../../asset/img/icon/plan.png";
import profile from "../../asset/img/icon/profile.png";
import profile_logo from "../../asset/img/icon/profile_logo.png";
import logo from "../../asset/img/logo.png";
import portugal from "../../asset/img/portugal.png";
import spain from "../../asset/img/spain.png";
import { io } from "socket.io-client";
import us from "../../asset/img/us.png";
import managment from "../../asset/img/managment.png";
import coupon from "../../asset/img/coupon.png";
import promotezone from "../../asset/img/promote-zone.png";
import trashads from "../../asset/img/trash-ads.png";
import { useDispatch, useSelector } from "react-redux";
import { isJoinRequest } from "../../actions";
import JoinRequest from "../../component/joinRequest";
const AdminSidebar = () => {
  const data = useSelector((state) => state.user_data);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useMemo(() => io(process.env.REACT_APP_CHAT_URL), []);
  const role = localStorage.getItem("userRole");
  const [notification, setNotification] = useState(0);
  const [Adslocation, setAdslocation] = useState(false);

  // const toggleSidebar = useSelector((state) => state.isSidebar)
  // const [isProfileDrop, setProfileDrop] = useState(false)
  // const [sideDrop, setSideDrop] = useState("")
  // console.log(sideTab)
  // const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isSupport, setIsSupport] = useState(false);
  const support = localStorage.getItem("userRole").toLowerCase();
  const [isMarketingOpen, setIsMarketingOpen] = useState(false);
  const [advertiserLocation, setAdvertiserLocation] = useState(false);
  const [userActiveLocation, setUserActiveLocation] = useState(false);
  const [myAccountLocation, setMyAccountLocation] = useState(false);
  const [toggleNotification, setToggleNotification] = useState(false);
  const [allNotification, setAllNotification] = useState([]);
  const [clearNotification, setClearNotification] = useState(false);
  const iconRef = useRef(null);

  const handleSearch = async () => {
    try {
      const response = await fetch(
        `https://socialmedia.quickvisit.me/api/get/globaly/search?search=${searchInput}`
      );
      if (!response.ok) {
        console.error(response);
      }
      const data = await response.json();
      console.log(data.advertisers);
      setSearchResult(data.advertisers);
    } catch (error) {
      console.error(error);
      // console.log(error);
    }
  };
  useEffect(() => {
    console.log(support);
    if (support && support == "support") {
      setIsSupport(true);
    }
  }, [support]);

  console.log(searchResult);
  useEffect(() => {
    handleSearch();
  }, [searchInput]);

  function handleSidebarToggle(event) {
    event.preventDefault();
    if (window.innerWidth < 767) {
      document.body.classList.toggle("sb-sidenav-toggled");
      localStorage.setItem(
        "sb|sidebar-toggle",
        document.body.classList.contains("sb-sidenav-toggled")
      );
    }
  }
  function handleSidebarButton(event) {
    event.preventDefault();
    document.body.classList.toggle("sb-sidenav-toggled");
    localStorage.setItem(
      "sb|sidebar-toggle",
      document.body.classList.contains("sb-sidenav-toggled")
    );
  }
  // window.onload = () => {
  //   socket.on("join room request to admin", (userRoom, name) => {
  //     setNotification(notification + 1);
  //   });
  // };

  useEffect(() => {
    // socket.on("connect", () => {
    //   console.log("socket ID: ", socket.id);
    // });
    socket.on(
      "join room request to admin",
      (userRoom, name, msg, profile, time) => {
        dispatch(
          isJoinRequest({
            flag: true,
            roomName: userRoom,
            name: name,
            msg: msg,
            profile: profile,
            time: time,
            accepted: false,
          })
        );
      }
    );
    const sidebarToggle = document.body.querySelector("#sidebarToggle");

    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", handleSidebarButton);

      return () => {
        sidebarToggle.removeEventListener("click", handleSidebarButton);
      };
    }
  }, []);

  useEffect(() => {
    const currentLocation = location.pathname;

    // Define arrays for Ads and Marketing routes
    const adsRoutes = [
      "/admin/totalAds",
      "/admin/latestAds",
      "/admin/pauseAds",
      "/admin/trashAds",
      "/admin/viewAds",
    ];

    const marketingRoutes = [
      "/admin/Coupon",
      "/admin/promotezone",
      "/admin/createCoupon",
      "/admin/createpromotezone",
    ];
    const advertiserRoutes = ["/admin/alladvertiser", "/admin/viewAdvertiser"];
    const userRoutes = ["/admin/user", "/admin/createUser", "/admin/editUser"];
    const myAccount = ["/admin/myAccount", "/admin/updatePassword"];

    // Check if the current location is in either Ads or Marketing routes
    const isInAdsRoutes = adsRoutes.some((route) =>
      currentLocation.startsWith(route)
    );
    const isInMarketingRoutes = marketingRoutes.some((route) =>
      currentLocation.startsWith(route)
    );
    const isInAdvertiserRoutes = advertiserRoutes.some((route) =>
      currentLocation.startsWith(route)
    );
    const isUserRoutes = userRoutes.some((route) =>
      currentLocation.startsWith(route)
    );
    const isMyAccountRoutes = myAccount.some((route) =>
      currentLocation.startsWith(route)
    );

    // Set states based on the current location
    setIsMarketingOpen(isInMarketingRoutes);
    setAdslocation(isInAdsRoutes);
    setAdvertiserLocation(isInAdvertiserRoutes);
    setUserActiveLocation(isUserRoutes);
    setMyAccountLocation(isMyAccountRoutes);
  }, [location]);

  // useEffect(() => {
  //   const currentLocation = location.pathname;
  //   // console.log(currentLocation);

  //   if (
  //     currentLocation === "/admin/totalAds" ||
  //     currentLocation === "/admin/latestAds" ||
  //     currentLocation === "/admin/pauseAds" ||
  //     currentLocation === "/admin/trashAds" ||
  //     currentLocation === "/admin/Coupon" ||
  //     currentLocation === "/admin/promotezone"
  //   ) {
  //     setIsMarketingOpen(true);
  //     setAdslocation(true);
  //   } else {
  //     setIsMarketingOpen(false);
  //     setAdslocation(false);
  //   }
  // }, [location]);
  const viewAdvertiser = (id) => {
    console.log(id);
    navigate(`search-view-profile?id=${id}`);
  };

  const handleClick = () => {
    setToggleNotification((prev) => !prev);
    if (iconRef.current) {
      const ariaExpanded = iconRef.current.getAttribute("aria-expanded");
      console.log("aria-expanded value:", ariaExpanded);
    }
  };
  const getNotification = async () => {
    try {
      const response = await fetch(
        "https://socialmedia.quickvisit.me/api/get/latest/notifiaction"
      );
 
      if (!response.ok) {
        console.error(
          "Failed to get data:",
          response.status,
          response.statusText
        );
        return;
      }
 
      const notificationData = await response.json();
      console.log(notificationData.data);
      if(notificationData.data){
        const unseenNotifications = notificationData.data.filter(notification => notification.seen_status == 0);
        console.log(unseenNotifications)
       
        setAllNotification(unseenNotifications.length > 0 ? unseenNotifications : []);
      }
 
 
     
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
 
  const handleClearNotification = async () => {
    try {
      const response = await fetch(
        "https://socialmedia.quickvisit.me/api/notification/seen/update",
        {
          method: "GET", // Use GET method
          headers: {
            "Content-Type": "application/json", // Set the content type if needed
          },
        }
      );
 
      if (!response.ok) {
        console.error(
          "Failed to clear notifications:",
          response.status,
          response.statusText
        );
        return; // Exit if the response is not okay
      }
 
      const data = await response.json();
      console.log("Notifications cleared successfully:", data);
     
      if (data.status) {
        setClearNotification(true);
        // Optionally, you may want to fetch notifications again after clearing
        await getNotification();
      }
 
    } catch (error) {
      console.error("An error occurred while clearing notifications:", error);
    }
  };
 
  useEffect(() => {
    getNotification()
    const pollNewNotification=setInterval(getNotification,10000)
    
    return () => clearInterval(pollNewNotification);
  }, [toggleNotification, clearNotification]);

  return (
    <>
      {/* <JoinRequest /> */}
      <div id="wrapper" className="d-flex" style={{ zIndex: 1000 }}>
        <div id="sidebar-wrapper">
          <div className="d-flex flex-column flex-shrink-0 text-white sidebar">
            <div className="sidebar-heading">
              <div className="d-flex justify-content-center align-items-center">
                <span
                  className="dashboard-logo"
                  onClick={() => navigate("/admin")}
                >
                  <img src={logo} alt="logo" className="img-fluid" />
                </span>
                <button
                  className="btn togglebtn position-absolute end-0"
                  onClick={handleSidebarButton}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 6L6 18"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 6L18 18"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="list-group list-group-flush" id="accordion">
              <ul className="nav nav-pills flex-column mb-auto sidebar_advertiser">
                <li>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      exact
                      activeClassName="active"
                      to="/admin"
                      // to={isSupport ? "/support" : "/admin"}
                      className="nav-link text-white d-flex align-items-center sidebarmenu"
                      end
                    >
                      <img src={dashboard} alt="dashboard" />
                      <span className="mx-3 iconmenu">Dashboard</span>
                    </NavLink>
                  </div>
                </li>
                <li>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/alladvertiser"
                      className={
                        advertiserLocation
                          ? "nav-link text-white d-flex align-items-center sidebarmenu active"
                          : "nav-link text-white d-flex align-items-center sidebarmenu"
                      }
                    >
                      <img src={profile} alt="profile" />
                      <span className="mx-3 iconmenu">
                        <span>Advertisers</span>
                      </span>
                    </NavLink>
                  </div>
                </li>

                <li>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <Link
                      to="#"
                      onClick={() => navigate("/admin/totalAds")}
                      className={
                        Adslocation
                          ? "nav-link text-white position-relative d-flex align-items-center sidebarmenu active"
                          : "nav-link text-white position-relative d-flex align-items-center sidebarmenu"
                      }
                      exact
                      data-bs-toggle="collapse"
                      data-bs-auto-close="outside"
                      data-bs-target="#submenu1"
                      aria-expanded={Adslocation ? true : false}
                    >
                      <img src={ads} alt="profile" />
                      <span className="mx-3 iconmenu menudropdown">
                        <span>Ads </span>
                        <i className="fa fa-caret-fa float-end position-absolute"></i>
                      </span>
                    </Link>
                    <div
                      id="submenu1"
                      className={`accordion-collapse collapse ${
                        Adslocation ? "show" : "hide"
                      }`}
                      data-bs-parent="#accordion"
                    >
                      <ul className="navi nav-list">
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeClassName="active"
                              to="/admin/totalAds"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={createAds} alt="profile" />
                              <span className="iconmenu">
                                {" "}
                                &nbsp; Total Ads
                              </span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeClassName="active"
                              to="/admin/latestAds"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={activeAds} alt="profile" />
                              <span className="iconmenu">
                                {" "}
                                &nbsp; Recent Ads
                              </span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeClassName="active"
                              to="/admin/pauseAds"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={expiredAds} alt="profile" />
                              <span className="iconmenu">
                                {" "}
                                &nbsp; Paused Ads
                              </span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeClassName="active"
                              to="/admin/trashAds"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={trashads} alt="profile" />
                              <span className="iconmenu"> &nbsp; Trash</span>
                            </NavLink>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="accordion-item"
                    style={{ background: "none", border: "none" }}
                  >
                    <Link
                      to="/admin/Coupon"
                      onClick={() => navigate("/admin/Coupon")}
                      className={
                        isMarketingOpen
                          ? "nav-link text-white position-relative d-flex align-items-center sidebarmenu active"
                          : "nav-link text-white position-relative d-flex align-items-center sidebarmenu"
                      }
                      exact
                      data-bs-toggle="collapse"
                      data-bs-auto-close="outside"
                      data-bs-target="#submenu2"
                      aria-expanded={isMarketingOpen ? true : false}
                    >
                      <img src={managment} alt="profile" />
                      <span className="mx-3 iconmenu menudropdown">
                        <span>Marketing </span>
                        <i className="fa fa-caret-fa float-end position-absolute"></i>
                      </span>
                    </Link>
                    <div
                      id="submenu2"
                      className={`accordion-collapse collapse ${
                        isMarketingOpen ? "show" : "hide"
                      }`}
                      data-bs-parent="#accordion"
                    >
                      <ul className="navi nav-list">
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeClassName="active"
                              to="/admin/Coupon"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={coupon} alt="profile" />
                              <span className="iconmenu"> &nbsp; Coupon</span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeClassName="active"
                              to="/admin/promotezone"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={promotezone} alt="profile" />
                              <span className="iconmenu">
                                {" "}
                                &nbsp; Promote Zone
                              </span>
                            </NavLink>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                {/* <li
                  className={`${
                    role === "Manager" || role === "Support"
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/adsSales"
                      className="nav-link text-white  d-flex align-items-center sidebarmenu"
                    >
                      <img src={availability} alt="profile" />
                      <span className="mx-3 iconmenu">Ads Sales</span>
                    </NavLink>
                  </div>
                </li>

                <li
                  className={`${
                    role === "Manager" || role === "Support"
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <div className="accordion-item">
                    <Link
                      activeclassname="active"
                      to="#"
                      className="nav-link text-white position-relative d-flex align-items-center sidebarmenu"
                      exact="true"
                      data-toggle="collapse"
                      data-bs-auto-close="outside"
                      data-target="#submenu"
                    >
                      <img src={ads} alt="profile" />
                      <span className="mx-3 iconmenu menudropdown">
                        <span>Reports</span>
                        <i className="fa fa-caret-fa float-end position-absolute"></i>
                      </span>
                    </Link>
                    <div
                      id="submenu"
                      className="accordion-collapse collapse"
                      data-parent="#accordion"
                    >
                      <ul className="navi nav-list">
                        <li className="dropdown-item">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/advertiserRevenue"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={createAds} alt="profile" />
                              <span className="iconmenu">
                                {" "}
                                &nbsp; Advertiser
                              </span>
                            </NavLink>
                          </div>
                        </li>
                        <li className="dropdown-item ">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/revenueReport"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={activeAds} alt="profile" />
                              <span className="iconmenu"> &nbsp; Revenue</span>
                            </NavLink>{" "}
                          </div>
                        </li>
                        <li className="dropdown-item ">
                          <div onClick={handleSidebarToggle}>
                            <NavLink
                              activeclassname="active"
                              to="/admin/salesReport"
                              className="nav-link text-white d-flex align-items-center p-0 sidebarmenu sidebarmenuitem"
                            >
                              <img src={expiredAds} alt="profile" />
                              <span className="iconmenu"> &nbsp; Sales</span>
                            </NavLink>{" "}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li> */}

                <li
                  className={`${
                    role === "Manager" || role === "Super_Admin"
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/Admin-MyMessage"
                      className="nav-link text-white  d-flex align-items-center sidebarmenu"
                    >
                      <img src={message} alt="message" />
                      <span className="mx-3 iconmenu">Chat</span>
                    </NavLink>
                  </div>
                </li>
                <li
                  className={`${
                    role === "Manager" || role === "Support"
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/user"
                      className={
                        userActiveLocation
                          ? "nav-link text-white  d-flex align-items-center sidebarmenu active"
                          : "nav-link text-white  d-flex align-items-center sidebarmenu"
                      }
                    >
                      <img src={account} alt="profile" />
                      <span className="mx-3 iconmenu">Users</span>
                    </NavLink>
                  </div>
                </li>

                <li>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/myAccount"
                      className={
                        myAccountLocation
                          ? "nav-link text-white  d-flex align-items-center sidebarmenu active"
                          : "nav-link text-white  d-flex align-items-center sidebarmenu "
                      }
                    >
                      <img src={account} alt="profile" />
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6"/>
</svg> */}
                      <span className="mx-3 iconmenu">My Account</span>
                    </NavLink>
                  </div>
                </li>

                <li>
                  <div onClick={handleSidebarToggle}>
                    <NavLink
                      activeclassname="active"
                      to="/admin/notification"
                      className="nav-link text-white  d-flex align-items-center sidebarmenu"
                    >
                      {/* <img src={notifica} alt="profile" /> */}
                      {/* <i class="bi bi-bell" style={{color:'red'}}></i> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        fill="lightgray"
                        class="bi bi-bell"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                      </svg>
                      <span className="mx-3 iconmenu">Notification</span>
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* NavBar */}

        <div className="page-content-wrapper ">
          <nav className="navbar navbar-expand-lg dash-header" id="header">
            <div className="container-fluid">
              <div className="text-light togglebar" id="sidebarToggle">
                <i className="fa fa-bars-staggered"></i>
              </div>

              <span
                className="dashboard-logo-menu"
                onClick={() => navigate("/admin")}
              >
                <img src={logo} alt="logo" className="img-fluid" />
              </span>

              <span className="position-relative input-wrapper dropdown">
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control form-control-ctm searchip dropdown-toggle"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
                <i className="fa fa-magnifying-glass position-absolute mx-3 mt-2 top-0 end-0 fglass"></i>

                {searchResult ? (
                  <ul
                    className="dropdown-menu"
                    style={{ backgroundColor: "black", width: "100%" }}
                  >
                    {searchResult.length > 0 ? (
                      searchResult.map((user, i) => (
                        <li key={i}>
                          <a
                            className="dropdown-item search-dropdown"
                            onClick={() => viewAdvertiser(user.ad_id)}
                            style={{ color: "red" }}
                          >
                            {user.username}
                          </a>
                        </li>
                      ))
                    ) : (
                      <li>
                        <a
                          className="dropdown-item search-dropdown-1"
                          href="#"
                          style={{ color: "red" }}
                        >
                          No Result
                        </a>
                      </li>
                    )}
                  </ul>
                ) : null}
              </span>
              <span className="nav-flag">
                <span className="navflag mx-1 navflagdash">
                  <img src={us} alt="us flag" />
                </span>
                <span className="navflag mx-1 navflagdash nav-flag-spain">
                  <img src={portugal} alt="prt flag" />
                </span>
                <span className="navflag mx-1 navflagdash">
                  <img src={spain} alt="Spain flag" />
                </span>
              </span>

              <span className="navbar-nav position-relative me-3">
                <span className="nav-bell dropstart ">
                  {allNotification.length > 0 ? (
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger rounded-circle mobile-noti">
                      {allNotification && allNotification.length}
                    </span>
                  ) : null}
                  <i
                    className="fa-regular fa-bell "
                    data-bs-toggle="dropdown"
                    aria-expanded={toggleNotification}
                    onClick={handleClick}
                  ></i>

                  <ul
                    class="dropdown-menu dropdwon position-relative"
                    style={{ backgroundColor: "black" }}
                  >
                    <li className="d-flex justify-content-between mx-3 mt-1 mb-0 pb-0">
                      <h6 className="text-danger">All Notifications</h6>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-x-circle text-danger"
                        viewBox="0 0 16 16"
                        style={{ cursor: "pointer" }}
                        onClick={handleClearNotification}
                      >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                      </svg>
                    </li>
                    <li>
                      <hr class="dropdown-divider bg-danger p-0 m-0 mb-2" />
                    </li>
                    {allNotification.length > 0 ? (
                      allNotification.map((notification) => (
                        <li key={notification.id}>
                          {" "}
                          {/* Use a unique id if available */}
                          <a className="dropdown-item text-danger d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              fill="currentColor"
                              className="bi bi-person-circle"
                              viewBox="0 0 16 16"
                              style={{ marginRight: "0.5rem" }}
                            >
                              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                              <path
                                fillRule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                              />
                            </svg>
                         {notification.message}
                          </a>
                        </li>
                      ))
                    ) : (
                      <li>
                        <a class="dropdown-item text-danger" href="#">
                          No Notification
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-patch-exclamation ms-4"
                            viewBox="0 0 16 16"
                          >
                            <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0z" />
                            <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911z" />
                          </svg>
                        </a>
                      </li>
                    )}

                    {/* <li>
                      <a class="dropdown-item text-danger" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item text-danger" href="#">
                        Something else here
                      </a>
                    </li> */}

                    <li>
                      <hr class="dropdown-divider bg-danger" />
                    </li>
                    <li>
                      <a class="dropdown-item text-danger" href="/admin/notification">
                        View all
                      </a>
                    </li>
                  </ul>

                  {/* {notification} */}
                </span>
                <span
                  className="pro"
                  exact="true"
                  activeclassname="active"
                  data-toggle="collapse"
                  data-target="#submenu4"
                >
                  <div
                    className="dropdown-icon d-flex align-items-center "
                    type="button"
                    data-bs-toggle="dropdown"
                    data-auto-close="outside"
                    aria-expanded="false"
                  >
                    <span className="profile_user">
                      <img
                        src={
                          data.profile_photo ? data.profile_photo : profile_logo
                        }
                        alt="profile"
                        className=""
                      />
                      {/* <i className="arrow-down"></i> */}
                    </span>
                  </div>
                  <ul
                    className="dropdown-menu collapse profile_menu"
                    id="submenu4"
                  >
                    <li>
                      <Link className="dropdown-item" to="/admin/myAccount">
                        My Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/"
                        onClick={() => localStorage.clear()}
                      >
                        Log Out
                      </Link>
                    </li>
                  </ul>
                </span>
              </span>
            </div>
          </nav>

          <Outlet />

          <div className=" p-3 fixed-bottom1">
            <div className="container-fluid">
              <div className="d-flex align-items-center justify-content-between small text-center">
                <div className="text-muted">
                  <span className="copyright quick-links footer_height text-light">
                    Copyright © 2024{" "}
                    <Link to="/" className="text-light">
                      QuickVisit.com
                    </Link>{" "}
                    All Rights Reserved{" "}
                    <Link to="/terms-of-use" className="text-light">
                      Term and Conditions
                    </Link>
                    .
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSidebar;
